import React, { Component } from "react";

export default class Youtube extends Component {
  componentDidMount() {
    const Plyr = require("plyr");
    const options = {
      controls: [
        "play-large", // The large play button in the center
        "play", // Play/pause playback
        "progress", // The progress bar and scrubber for playback and buffering
        "current-time", // The current time of playback
        "mute", // Toggle mute
        "volume", // Volume control
        "pip", // Picture-in-picture (currently Safari only)
        "airplay", // Airplay (currently Safari only)
        "fullscreen" // Toggle fullscreen.
      ]
    };
    this.player = Plyr.setup("#player", options);
  }

  componentWillUnmount() {
    if (this.player.length > 0) {
      for (const playerEl of this.player) {
        playerEl.destroy();
      }
    }
  }

  render() {
    const { url } = this.props;
    return (
      <div
        id="player"
        data-plyr-provider="youtube"
        data-plyr-embed-id={url}
      ></div>
    );
  }
}
