import React from "react";
import OneStar from "./Stars/One";
import TwoStars from "./Stars/Two";
import ThreeStars from "./Stars/Three";
import FourStars from "./Stars/Four";
import FiveStars from "./Stars/Five";

const StarsRating = ({ score }) => {
  switch (score) {
    case 1:
      return <OneStar />;
    case 2:
      return <TwoStars />;
    case 3:
      return <ThreeStars />;
    case 4:
      return <FourStars />;
    case 5:
      return <FiveStars />;
    default:
      break;
  }
};

export default function RenderRating({ score }) {
  return <StarsRating score={score} />;
}
