import React from "react";
import StarFilled from "./StarFilled";

const ThreeStars = () => (
  <React.Fragment>
    <StarFilled />
    <StarFilled />
    <StarFilled />
  </React.Fragment>
);

export default ThreeStars;
