export const replaceEscapedLetters = (str) => {
  if (str.includes("&amp;")) {
    str = str.replace(/&amp;/g, "&");
  }
  if (str.includes("&#038;")) {
    str = str.replace(/&#038;/g, "&");
  }
  if (str.includes("&ndash;")) {
    str = str.replace(/&ndash;/g, "–");
  }

  return str;
};